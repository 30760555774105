.App {
  background-color: white;
}

.is--pointer {
  cursor: pointer;
}

.is--not--pointer {
  cursor: none;
}
